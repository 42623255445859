import React, { Fragment } from "react"
import styled from "styled-components"
import PagesContext from "../contexes/PagesContext"
import { nthIndex } from "../util/strings"
import { Link } from "gatsby"
import { withTranslation, useI18next } from "gatsby-plugin-react-i18next"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import Arrow from "../assets/right-arrow.svg"
import H3 from "../partials/Headers/H3"

const SectionWrapper = styled.div`
  margin-top: 3rem;
  background: #f0f0f0;
  padding: 3rem 2rem;

  p {
    font-family: "Josefin Sans", sans-serif !important;
    font-size: 1.4rem;
    color: #333;
    margin: 0;
    padding: 0;
    dislay: flex;
  }

  p:nth-child(1) {
    font-size: 2rem;
    font-weight: 500;
  }

  H3 {
    text-align: left !important;
  }
`

const StyledArrow = styled(Arrow)`
  position: absolute;
  top: 0%;
  right: 0%;
  height: 100%;
  width: 60px;
  padding: 1.2rem;
  background: #505050;
  cursor: pointer;

  &:hover {
    .arrow {
      fill: #fe9677;
    }
  }
  .arrow {
    transition: fill 0.2s ease-in;
    fill: rgb(22, 179, 199);
  }
`

const StyledLink = styled(Link)`
  position: relative;
  color: #c4c4c4;
  text-decoration: none;
  padding: 1rem 1.4rem;
  margin: 1rem 0;
  display: flex;
  min-width: 90%;
  background-color: #333;
  transition: background-color 0.2s;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #333;
  }

  span {
    font-family: "Josefin Sans", sans-serif;
    color: white !important;
    line-height: 1.3;
  }

  &:after {
    content: "";
    background: red;
    height: 100%;
    width: 100px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`

const EndOfSubSection = (props) => {
  const { language } = useI18next()
  return (
    <PagesContext.Consumer>
      {(value) => {
        const currentPath = value.current.frontmatter.path
        let sectionPath = currentPath
        const sectionSeparator = nthIndex(currentPath, "/", 2)

        if (sectionSeparator === 3) {
          sectionPath = currentPath.substr(0, 10)
        } else {
          sectionPath = currentPath.substr(0, 7)
        }
        const sectionPages = value.all
          .filter((o) => o.path.startsWith(`${sectionPath}`))
          .sort((a, b) => {
            a = a.path.toLowerCase()
            b = b.path.toLowerCase()

            return a > b ? 1 : b > a ? -1 : 0
          })
        let currentPageIndex = null
        sectionPages.forEach((page, i) => {
          if (page.path !== currentPath) {
            return
          }
          currentPageIndex = i
        })

        let nextPart = null
        if (
          currentPageIndex !== null &&
          currentPageIndex !== sectionPages.length - 1
        ) {
          nextPart = sectionPages[currentPageIndex + 1]
        }
        const currentChapter = parseInt(sectionPath.split("-")[1])
        /*
        const regex = new RegExp("^/part-[1-9]$", "gm")

        const chapters = value.all
          .filter((o) => o.path.match(regex))
          .sort((a, b) => {
            return a.path > b.path ? 1 : -1
          })
        */
        let nextSectionPath
        if (language === "sv") {
          nextSectionPath = `/sv/part-${currentChapter + 1}`
        } else {
          nextSectionPath = `/part-${currentChapter + 1}`
        }

        const nextSectionPages = value.all
          .filter((o) => o.path.startsWith(`${nextSectionPath}`))
          .sort((a, b) => {
            a = a.path.toLowerCase()
            b = b.path.toLowerCase()

            return a > b ? 1 : b > a ? -1 : 0
          })
        return (
          <SectionWrapper>
            {nextPart && (
              <Fragment>
                <H3>{props.t("youHaveCompletedSection")}</H3>
                <p>{props.t("nextSection")} </p>
                <ButtonWrapper>
                  <StyledLink to={nextPart.path}>
                    <span>{nextPart.title}</span>
                    <StyledArrow />
                  </StyledLink>
                </ButtonWrapper>
              </Fragment>
            )}
            {nextPart === null && nextSectionPages.length > 0 && (
              <Fragment>
                <H3>{props.t("youHaveCompletedChapter")}</H3>
                <p>{props.t("nextChapter")} </p>
                <ButtonWrapper>
                  <StyledLink to={nextSectionPages[1].path}>
                    <span>{nextSectionPages[0].topic}</span>
                    <StyledArrow />
                  </StyledLink>
                </ButtonWrapper>
              </Fragment>
            )}
          </SectionWrapper>
        )
      }}
    </PagesContext.Consumer>
  )
}

export default withTranslation("common")(
  withSimpleErrorBoundary(EndOfSubSection),
)
