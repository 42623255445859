import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, navigate } from "gatsby"
import { withTranslation } from "gatsby-plugin-react-i18next"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import Container from "../components/Container"
import EndOfSubSection from "../components/EndOfSubSection"
import LoginStateContext, {
  LoginStateContextProvider,
} from "../contexes/LoginStateContext"
import PagesContext from "../contexes/PagesContext"
import getNamedPartials from "../partials"
import ChapterBox from "../partials/ChapterBox"
import HeroSection from "../partials/Contentpage/HeroSection"
import CourseProgress from "../partials/CourseProgress/index"
import { getCachedUserDetails, loggedIn } from "../services/moocfi"
import { tryToScrollToSelector } from "../util/dom"
import { respond } from "../_respond"
import Layout from "./Layout"
import "./remark.css"

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
  font-size: 1em;
`

const ContentWrapper = styled.article`
  ${respond.mobile`
  width: 100%;
  `}

  h1 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    font-size: clamp(26px, 3vw, 40px);
    text-align: center;
    text-transform: uppercase;
    line-height: 1.25;
    color: #26252a;
    margin-bottom: 2rem;
  }

  H2 {
    text-align: center;
  }

  H3 {
    text-align: center;
  }
`

const UpLink = styled(Link)`
  color: #332c2cb3 !important;
  font-weight: bold;
  margin-bottom: 1rem !important;
  display: block;

  :hover {
    text-decoration: none;
    color: #805050b3 !important;
  }
`

export default withTranslation("common")(
  class CourseContentTemplate extends React.Component {
    static contextType = LoginStateContext

    async componentDidMount() {
      if (typeof window !== "undefined" && window.location.hash) {
        const selector = window.location.hash
        setTimeout(() => {
          tryToScrollToSelector(selector)
        }, 100)
        setTimeout(() => {
          tryToScrollToSelector(selector)
        }, 500)
        setTimeout(() => {
          tryToScrollToSelector(selector)
        }, 1000)
        setTimeout(() => {
          tryToScrollToSelector(selector)
        }, 2000)
      }

      if (!loggedIn()) {
        return
      }
      let userInfo = await getCachedUserDetails({ language: "fi" })
      const student_language = userInfo?.extra_fields?.language
      if (student_language === undefined) {
        navigate("/missing-info")
      }
    }

    render() {
      const { data } = this.props
      const { frontmatter, htmlAst } = data.page
      const allPages = data.allPages.edges.map((o) => o.node?.frontmatter)
      const partials = getNamedPartials()
      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: partials,
      }).Compiler
      const language = data.locales.edges[0].node.language

      const correctForLanguagePrefixPath = new RegExp("^/[a-z]{2}/", "gm")

      const languageCorrectedPath = frontmatter.path.split(
        correctForLanguagePrefixPath,
      )

      let parentSectionNumber = languageCorrectedPath[0].substr(6, 1)

      if (languageCorrectedPath.length === 2) {
        parentSectionNumber = languageCorrectedPath[1].substr(5, 1)
      }

      const parentSectionTitle = `Osio ${frontmatter.path.substr(6, 1)}`

      const chooseChapterHeader = {
        1: ["#fe9b7e", "chap1"],
        2: ["#0096d7", "chap2"],
        3: ["#fe9ca5", "chap3"],
        4: ["#FE9B7E", "chap4"],
        5: ["#0096D7", "chap5"],
        6: ["#fe9ca5", "chap6"],
      }

      const filePath = data.page.fileAbsolutePath.substring(
        data.page.fileAbsolutePath.lastIndexOf("/data/"),
        data.page.fileAbsolutePath.length,
      )

      return (
        <Fragment>
          <Helmet title={frontmatter.title} />
          <PagesContext.Provider
            value={{
              all: allPages,
              current: { frontmatter: frontmatter, filePath: filePath },
              language,
            }}
          >
            <LoginStateContextProvider>
              <Layout
                navcolor={chooseChapterHeader[`${parentSectionNumber}`][0]}
              >
                <HeroSection
                  title={parentSectionTitle}
                  subtitle={this.props.t(
                    `common:chapterTitle${parentSectionNumber}`,
                  )}
                  intro={this.props.t(
                    `common:chapterQuestion${parentSectionNumber}`,
                    { returnObjects: true },
                  )}
                  bgColor={chooseChapterHeader[`${parentSectionNumber}`][0]}
                  heroIcon={chooseChapterHeader[`${parentSectionNumber}`][1]}
                ></HeroSection>
                <Container>
                  {/*<LanguageWarning />*/}
                  <ContentWrapper>
                    <ChapterBox />
                    <h1>
                      {/*                     {" "}
                    {chooseChapterValue[frontmatter.path.substr(11, 1)]}.{" "} */}
                      {frontmatter.title}
                    </h1>
                    {renderAst(htmlAst)}
                    <CourseProgress />
                    <EndOfSubSection />
                  </ContentWrapper>
                </Container>
                {/*  <CoursePageFooter />*/}
              </Layout>
            </LoginStateContextProvider>
          </PagesContext.Provider>
        </Fragment>
      )
    }
  },
)

export const pageQuery = graphql`
  query ($path: String!, $language: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            topic
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
