import React from "react"
import styled, { keyframes } from "styled-components"
import { respond } from "../../_respond"
import H1 from "../Headers/H1"
import ImageImporter from "./ImageImporter"

const openAnimation = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`

const slideDown = keyframes`
from { opacity: 0; height: 0; padding: 0;}
to { opacity: 1; height: 100%; padding: 10px;}
`

const HeroSection = styled.div`
  background: ${(props) => props.bgColor};
  padding: 3rem 6rem;
  justify-content: center;
  z-index: 99;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  ${respond.mobile`
  grid-template-columns: 1fr;
  padding: 2rem 1.4rem;
  `}
  ${respond.xs`
  grid-template-columns: 1fr;
  padding: 2rem;
  `}
  ${respond.sm`
  grid-template-columns: repeat(2, 1fr);
  padding: 3rem 2rem;
  `}
  ${respond.md`
  grid-template-columns: repeat(2, 1fr);
  padding: 3rem 6rem;
  `}
`
const TextWrapper = styled.div`
  padding: 0rem 0rem 4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex: 1;
  @media (max-width: 1000px) {
    width: 90%;
    padding-top: 5rem;
  }

  ${respond.mobile`
  padding: 1rem;
  `}

  ${respond.sm`
  width: 100%;
  padding: 0 1rem;
`}

details {
    border: 1px solid rgba(0, 0, 0, 0.2);

    ${respond.mobile`
  width: 100%;
  `}
    ${respond.xs`
  width: 80%;
  `}
  ${respond.sm`
  width: 80%;
  `}
  ${respond.md`
  width: 80%;
  `}
  }

  details[open] summary ~ * {
    animation: ${openAnimation} 0.3s ease-in-out;
    color: #333;
  }

  details[open] > div {
    animation-name: ${slideDown};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  details summary::-webkit-details-marker {
    display: none;
  }

  details[open] > summary {
    color: #1c1c1c;
  }

  details summary {
    width: 100%;
    padding: 1rem 0.6rem;
    position: relative;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: medium;
    list-style: none;
    color: #333;
    font-family: "Josefin Sans", sans-serif;
    background: rgba(0, 0, 0, 0.1);
  }

  details summary:after {
    content: "+";
    color: ${(props) => (props.textcolor === "light" ? "white" : "black")};
    position: absolute;
    font-size: 2.4rem;
    line-height: 0;
    margin-top: 0.75rem;
    right: 5%;
    font-weight: 200;
    transform-origin: center;
    transition: all 200ms linear;
  }
  details[open] summary:after {
    transform: rotate(45deg);
    font-size: 2.4rem;
  }
  details[open] summary {
    font-weight: 600;
    opacity: 0.9;
  }
  details summary {
    outline: 0;
  }

  /* details  */
  ul {
    margin: 0 0.5rem 1.8rem 1rem;
  }

  /* details  */
  ul li {
    font-size: 1.1rem;
    font-family: "Lato", sans-serif;
    margin: 0 0 0.2rem;
    padding-left: 8px;
    line-height: 1.8;
    list-style-position: outside;
  }

  ul li::marker {
    content: "⚉";
    text-align: center;
    margin-left: 2rem !important;
    color: #333;
  }
`
/*
const HeroText = styled.div`
  color: #26252a;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Josefin Sans";
  width: 90%;
  display: grid;

  ${respond.mobile`
  font-size: 1rem;
  width: 100%;
`}

  ${respond.xs`
  font-size: 1.4rem;
  font-weight: 400;
  width: 90%;
  `}

  ${respond.lg`
  width: 90%;
  font-size: 1.4rem;
`}
`
*/
const Herosection = ({ bgColor, subtitle, intro, heroIcon }) => {
  let introText = ""
  intro.map((text) => (introText = introText.concat(text + " ")))
  return (
    <div>
      <HeroSection bgColor={bgColor}>
        <TextWrapper>
          <H1>
            <a
              style={{
                color: "rgba(28, 59, 64)",
                fontFamily: "Josefin Sans",
              }}
            >
              {subtitle}
            </a>
          </H1>
          <ul>
            {intro.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </TextWrapper>
        <ImageImporter icon={heroIcon} />
      </HeroSection>
    </div>
  )
}

export default Herosection
