import React from "react"
import styled from "styled-components"
import bgIcon from "../../images/bg-icon.svg"
import chap7 from "../../images/chap-7.svg"
import chap1 from "../../images/chapter-1.svg"
import chap2 from "../../images/chapter-2.svg"
import chap3 from "../../images/chapter-3.svg"
import chap4 from "../../images/chapter-4.svg"
import chap5 from "../../images/chapter-5.svg"
import chap6 from "../../images/chapter-6.svg"
import ethicsIcon from "../../images/ethics-icon.svg"
import philIcon from "../../images/phil-icon.svg"
import techIcon from "../../images/tech-icon.svg"
import { respond } from "../../_respond"

const chooseIcon = {
  chap1: chap1,
  chap2: chap2,
  chap3: chap3,
  chap4: chap4,
  chap5: chap5,
  chap6: chap6,
  chap7: chap7,
  tech: techIcon,
  phil: philIcon,
  bg: bgIcon,
  ethics: ethicsIcon,
  undefined: chap1,
}

const ImageWrapper = styled.div`
  ${respond.mobile`
  padding: 0;
  `}
  ${respond.xs`
  padding: 1rem 2rem 2rem 0;

  `}
  ${respond.sm`

  `}
  ${respond.md`

  `}
`
const Image = styled.img`
  height: auto;
  flex: 0.4;
`

const ImageImporter = (props) => {
  return (
    <ImageWrapper>
      <Image src={chooseIcon[props.icon]} alt="icon" />
    </ImageWrapper>
  )
}

export default ImageImporter
